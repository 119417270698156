<template>
    <div class="carousel__item">
        <div class="max-w-lg px-2 py-3 rounded-md shadow-lg bg-white m-5">
            <p class="text-sm text-left font-medium leading-5 text-gray-500">12.09.2019</p>

            <div class="mt-2 flex items-center space-x-2">
                <div class="flex flex-shrink-0 rounded-full border border-gray-200">
                    <img src="http://127.0.0.1:8000/storage/students/2022/x2OE9CRdJ0Y9KRy7.png" alt="" class="w-10 h-10 object-cover rounded-full">
                </div>
                <span class="text-sm font-semibold leading-5 text-gray-900">Abdullah Al Shahin</span>
                <div class="flex-shrink-0">
                    <svg fill="currentColor" viewBox="0 0 20 20" class="w-5 h-5 text-green-400">
                        <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path>
                    </svg>
                </div>
            </div>

            <div class="mt-2 text-left">
                <p class="text-sm font-semibold leading-5 text-gray-800"> Medical College: Dhaka Medical College </p>
                <p class="text-sm font-semibold leading-5 text-gray-800"> Merit Position: 65th </p>
            </div>

            <div class="space-y-1">
                <h3 class="font-semibold text-gray-800 text-left mt-3">Review :</h3>
                <p class="text-sm font-medium leading-5 text-gray-600 text-left">There's not much to say about YETI stainless steel tumblers that hasn't been said. There's a reason they're so highly rated. I filled mine with ice and water at 8:30am last week and drove to work sipping it. I left it in my car when I went into the office. </p>
            </div>
            
            <div class="text-right">
                <a href="asfd" type="button" class="text-white bg-orange-500 hover:bg-orange-800 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg px-2 py-1 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                    Read More
                    <svg class="w-3 h-3 ml-1 -mr-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "student-review-item",
        components: {
            
        }
    };
</script>