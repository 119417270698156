<template>

    <container max-w="6xl my-10">

        <div class="text-right mb-4">
            <btn :router-path="`/my/review/create`" variant="warning">Add Your Review</btn>
        </div>

        <router-view/>

        <p class="text-3xl text-center mt-2">Student Reviews</p>

        <div class="relative mt-3">
          <div class="grid md:grid-cols-1 lg:grid-cols-3 gap-4 w-full">
            <student-review-homepage-item v-for="(review, index) in reviews" :review="review" class="slide-item"  />
          </div>
        </div>

    </container>
</template>

<script>
import StudentReview from './student-review-item.vue';
import Position from '../../student/exams/components/position.vue';
import Container from "../../layouts/container";
import { mapGetters } from 'vuex';
import StudentReviewHomepageItem from "../home/components/student-review-homepage-item";
import Btn from "../../../components/btn";
export default {
    name: "student-review",
    components: {
        Btn,
        StudentReviewHomepageItem,
        StudentReview,
        Container,
        Position,
    },
    data: {

    },
    computed: {
        ...mapGetters({
            reviews: 'reviews/reviews',
        }),
    },
    created() {
        this.$store.dispatch('reviews/callAllReviews');
    },
};
</script>

<style scoped>

</style>
